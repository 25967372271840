import React from "react";
import { bannerContent } from "../../content/bannerContent";
import "./banner.scss";
import Banner from "./Banner";

const BannerContainer = () => {
  return <Banner bannerContent={bannerContent} />;
};

export default BannerContainer;
