import React from "react";
import BannerContainer from "../../components/Banner/BannerContainer";
import SliderLeaguesContainer from "../../components/SliderLeagues/SliderLeaguesContainer";
import SliderPartnersContainer from "../../components/SliderPartners/SliderPartnersContainer";





const Home = () => {
    return (
        <section className="home">
            <div className="home__container">
                <BannerContainer />
                <SliderLeaguesContainer />
                <SliderPartnersContainer />
            </div>
        </section>
    );
}

export default Home;