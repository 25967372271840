import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import "./sliderPartners.scss";
import { ISliderPartnersContent } from "../../types/contents";

interface SliderPartnersProps {
  sliderPartnersContent: ISliderPartnersContent;
}

const SliderPartners: React.FC<SliderPartnersProps> = ({
  sliderPartnersContent,
}) => {
  const swiperSlideItem = sliderPartnersContent.slides.map((item) => {
    return (
      <SwiperSlide key={item.id}>
        <div className="sliderPartners__item">
          <img className="sliderPartners__item_img" src={item.src} alt="logo" />
        </div>
      </SwiperSlide>
    );
  });

  return (
    <div className="sliderPartners">
      <div className="sliderPartners__container">
        <h3 className="sliderPartners__title">{sliderPartnersContent.title}</h3>
        <Swiper
          spaceBetween={0}
          navigation={false}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
          loop={true}
          breakpoints={{
            1440: {
              slidesPerView: 9,
            },
            1200: {
              slidesPerView: 7,
            },
            1024: {
              slidesPerView: 5,
            },
            768: {
              slidesPerView: 3,
            },
            0: {
              slidesPerView: 2,
            },
          }}
        >
          {swiperSlideItem}
        </Swiper>
      </div>
    </div>
  );
};

export default SliderPartners;
