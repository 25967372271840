import React from "react";
import BannerContainer from "../../components/Banner/BannerContainer";
import RequestFormContainer from "../../components/RequestForm/RequestFormContainer";

const ContactUs = () => {
  return (
    <section className="contactUs">
      <div className="contactUs__container">
        <BannerContainer />
        <RequestFormContainer />
      </div>
    </section>
  );
};

export default ContactUs;
