import React from "react";
import Header from "./Header";
import { headerContent } from "../../content/headerContent";



const HeaderContainer = () => {
    return(
        <Header headerContent={headerContent}/>
     );
}

export default HeaderContainer;