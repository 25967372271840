import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { IBannerContent } from "../../types/contents";

interface BannerProps {
  bannerContent: IBannerContent;
}

const Banner: React.FC<BannerProps> = ({ bannerContent }) => {
  const autoplay = {
    delay: 13000,
    disableOnInteraction: false,
  };

  const pagination = {
    clickable: true,
  };

  const swiperSlideItem = bannerContent.slides.map((item) => {
    return (
      <SwiperSlide key={item.id}>
        <div className={item.class}>
          <p className={item.class + "_text"}>{item.text}</p>
        </div>
      </SwiperSlide>
    );
  });

  return (
    <div className="banner">
      <div className="banner__container">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          autoplay={autoplay}
          loop={true}
          modules={[Autoplay, EffectFade, Pagination]}
          effect={"fade"}
          pagination={pagination}
          className="mySwiper"
        >
          {swiperSlideItem}
        </Swiper>
      </div>
    </div>
  );
};

export default Banner;
