import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "./sliderLeagues.scss";
import "swiper/css";
import "swiper/css/navigation";
import { ISliderLeaguesContent } from "../../types/contents";

interface SliderLeaguesProps {
  sliderLeaguesContent: ISliderLeaguesContent;
}

const SliderLeagues: React.FC<SliderLeaguesProps> = ({
  sliderLeaguesContent,
}) => {
  const swiperSlideItem = sliderLeaguesContent.slides.map((item) => {
    return (
      <SwiperSlide key={item.id}>
        <div className="sliderLeagues__item">
          <img className="sliderLeagues__item_img" src={item.src} alt="logo" />
          <p className="sliderLeagues__item_title">{item.title}</p>
          <p className="sliderLeagues__item_text">{item.text}</p>
        </div>
      </SwiperSlide>
    );
  });

  return (
    <div className="sliderLeagues">
      <div className="sliderLeagues__container">
        <h3 className="sliderLeagues__title">{sliderLeaguesContent.title}</h3>
        <Swiper
          spaceBetween={-20}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          loop={true}
          breakpoints={{
            1440: {
              slidesPerView: 5,
            },
            1200: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 2,
            },
            0: {
              slidesPerView: 1,
            },
          }}
        >
          {swiperSlideItem}
        </Swiper>
      </div>
    </div>
  );
};

export default SliderLeagues;
