import { IBannerContent } from "../types/contents";

export const bannerContent: IBannerContent = {
  slides: [
    {
      id: 0,
      text: "Own your own team!!!! Own FC Pirin!!!! Own the players, coaches and victories!!!! Don’t just watch a football game, make it interactive, make it yours!!!! Be a proud owner of a football team and you will never watch another football the same way again.",
      class: "banner__wc1",
    },
    {
      id: 1,
      text: "Have you ever thought about a chance to have your own players playing at the next WORLD CUP? Here is your chance to own the players, team and influence the future WORLD CUPS!!!!!!!!!",
      class: "banner__wc2",
    },
    {
      id: 2,
      text: "Don’t just watch the World Cup or for that matter any other football matches as it barely matters, be a participant, OWN the players, team, games and victories!!!!!",
      class: "banner__wc3",
    },
    {
      id: 3,
      text: "Make each weekend’s football game to be your game, your team’s game and your VICTORY!!!!",
      class: "banner__wc4",
    },
    {
      id: 4,
      text: "Have the next WORLD CUP to be won by your players!!!!",
      class: "banner__wc5",
    },
  ],
};
