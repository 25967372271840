import { ISliderPartnersContent } from "../types/contents";
import accorpa from "../assets/images/accorpa.bmp";
import acer from "../assets/images/acer.bmp";
import american_airlines from "../assets/images/american_airlines.bmp";
import canon from "../assets/images/canon.bmp";
import champion from "../assets/images/champion.bmp";
import dell from "../assets/images/dell.bmp";
import echampionship from "../assets/images/echampionship.bmp";
import fedex from "../assets/images/fedex.bmp";
import msi from "../assets/images/msi.bmp";
import nokia from "../assets/images/nokia.bmp";
import sony from "../assets/images/sony.bmp";
import sport24 from "../assets/images/sport24.bmp";
import target from "../assets/images/target.bmp";
import visa from "../assets/images/visa.bmp";


export const sliderPartnersContent: ISliderPartnersContent = {
  title: "People/Entities who like the Platform",
  slides: [
    {
      id: 0,
      src: accorpa,
    },
    {
      id: 1,
      src: acer,
    },
    {
      id: 2,
      src: american_airlines,
    },
    {
      id: 3,
      src: canon,
    },
    {
      id: 4,
      src: champion,
    },
    {
      id: 5,
      src: echampionship,
    },
    {
      id: 6,
      src: dell,
    },
    {
      id: 7,
      src: fedex,
    },
    {
      id: 8,
      src: msi,
    },
    {
      id: 9,
      src: nokia,
    },
    {
      id: 10,
      src: sony,
    },
    {
      id: 11,
      src: sport24,
    },
    {
      id: 12,
      src: target,
    },
    {
      id: 13,
      src: visa,
    },
  ],
};
