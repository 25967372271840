export const checkPageComplete = (
  num = 0,
  setState: (arg1: boolean) => void
) => {
  let countTry: number = num;
  const maxTry: number = 10;
  setTimeout(() => {
    if (document.readyState === "complete") {
      setState(false);
    } else if (countTry <= maxTry) {
      checkPageComplete(++countTry, setState);
    } else {
      window.location.reload();
    }
  }, 2000);
};
