import React from "react";
import "./requestForm.scss";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { IRequestFormContent } from "../../types/contents";
import { phoneRegExp } from "../../helpers/regexp";

interface RequestFormProps {
  requestFormContent: IRequestFormContent;
}

const RequestForm: React.FC<RequestFormProps> = ({ requestFormContent }) => {
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    message: Yup.string()
      .min(2, "Too Short!")
      .max(512, "Too Long!")
      .required("Required"),
    code: Yup.string().required("Required"),
  });

  return (
    <section className="requestForm">
      <div className="requestForm__container">
        <div className="requestForm__box">
          <h3 className="requestForm__title">{requestFormContent.title}</h3>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              message: "",
              code: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { resetForm }) => {
              // same shape as initial values
              console.log(values);
              resetForm();
            }}
          >
            {({ errors, touched }) => (
              <Form className="requestForm__form">
                <div className="requestForm__contacts">
                  <label htmlFor="firstName" className="requestForm__label">
                    First Name:
                  </label>
                  <Field
                    id="firstName"
                    name="firstName"
                    className="requestForm__input"
                  />
                  {errors.firstName && touched.firstName ? (
                    <div className="requestForm__error">{errors.firstName}</div>
                  ) : null}
                  <label htmlFor="lastName" className="requestForm__label">
                    Last Name:
                  </label>
                  <Field
                    id="lastName"
                    name="lastName"
                    className="requestForm__input"
                  />
                  {errors.lastName && touched.lastName ? (
                    <div className="requestForm__error">{errors.lastName}</div>
                  ) : null}
                  <label htmlFor="email" className="requestForm__label">
                    Email:
                  </label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    className="requestForm__input"
                  />
                  {errors.email && touched.email ? (
                    <div className="requestForm__error">{errors.email}</div>
                  ) : null}
                  <label htmlFor="phoneNumber" className="requestForm__label">
                    Phone Number:
                  </label>
                  <Field
                    id="phoneNumber"
                    name="phoneNumber"
                    className="requestForm__input"
                  />
                  {errors.phoneNumber && touched.phoneNumber ? (
                    <div className="requestForm__error">
                      {errors.phoneNumber}
                    </div>
                  ) : null}
                </div>
                <div className="requestForm__message">
                  <label htmlFor="message" className="requestForm__label">
                    Your message:
                  </label>
                  <Field
                    id="message"
                    name="message"
                    as="textarea"
                    className="requestForm__textarea"
                  />
                  {errors.message && touched.message ? (
                    <div className="requestForm__error">{errors.message}</div>
                  ) : null}
                  <div className="requestForm__submit">
                    <label htmlFor="code" className="requestForm__label">
                      Enter code:
                    </label>
                    <div>
                      <Field
                        id="code"
                        name="code"
                        className="requestForm__code"
                      />
                      {errors.code && touched.code ? (
                        <div className="requestForm__error">{errors.code}</div>
                      ) : null}
                    </div>
                    <img
                      src=""
                      className="requestForm__captcha"
                      alt="CAPTCHA"
                    />
                    <button type="submit" className="requestForm__button">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default RequestForm;
