import React from "react";
import "./footer.scss";
import { IFooterContent } from "../../types/contents";

interface IFooterProps {
  footerContent: IFooterContent;
}

const Footer: React.FC<IFooterProps> = ({ footerContent }) => {
  const footerTextItems = footerContent.texts.map((item) => {
    return (
      <p key={item.id} className="footer__item">
        {item.text}
      </p>
    );
  });

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__texts">{footerTextItems}</div>
        <div className="footer__image"></div>
      </div>
    </footer>
  );
};

export default Footer;
