import React from "react";
import "./header.scss";
import { NavLink } from "react-router-dom";
import { IHeaderContent } from "../../types/contents";

interface HeaderProps {
  headerContent: IHeaderContent;
}

const Header: React.FC<HeaderProps> = ({ headerContent }) => {
  const headerLinks = headerContent.links.map((item) => {
    if (item.id === 1) {
      return (
        <li key={item.id} className="header__link">
          <a href={item.link} rel="noreferrer" target="_blank">
            {item.name}
          </a>
        </li>
      );
    }
    return (
      <li key={item.id} className="header__link">
        <NavLink to={item.link}>{item.name}</NavLink>
      </li>
    );
  });

  return (
    <header className="header">
      <div className="header__container">
        <nav className="header__menu">
          <ul>{headerLinks}</ul>
        </nav>
        <div className="header__bottomBox">
          <div className="header__logo">
            <NavLink to={headerContent.logo.link}>
              <img
                className="header__logo_img"
                src={headerContent.logo.src}
                alt="Logo"
              />
            </NavLink>
          </div>
          <div className="header__button">
            <a
              href={headerContent.links[1].link}
              rel="noreferrer"
              target="_blank"
            >
              {headerContent.links[1].secondName?.toUpperCase()}
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
