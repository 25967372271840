import React, { useEffect, useState } from "react";
import "./App.css";
import "@fontsource/candal";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import ContactUsContainer from "./pages/ContactUs/ContactUsContainer";
import HomeContainer from "./pages/Home/HomeContainer";
import Preloader from "./components/Preloader/Preloader";
import { checkPageComplete } from "./helpers/chekPageComplete";

const App = () => {
  const [isPreloader, setIsPreloader] = useState<boolean>(true);

  useEffect(() => {
    checkPageComplete(0, setIsPreloader);
  }, []);

  if (isPreloader) {
    return (
      <div className="loader">
        <Preloader />
      </div>
    );
  }

  return (
    <Router>
      <MainLayout>
        <Routes>
          <Route path="/" element={<HomeContainer />} />
          <Route path="/contact-us" element={<ContactUsContainer />} />
        </Routes>
      </MainLayout>
    </Router>
  );
};

export default App;
