import footerImage from "../assets/images/footer.jpg";
import { IFooterContent } from "../types/contents";

export const footerContent: IFooterContent = {
  texts: [
    {
      id: 0,
      text: "Warning: All information, materials, functions and other content are copyrighted property of respective Sport Leagues, Corporations and Teams mentioned below. eChampionship is a concept, software and technology that is currently being marketed and offered to all respective Sport Leagues, Corporations and Teams. eChampionship does not redistribute nor claim to own any of the trademarks that are shown on the website or platform. eChampionship utilizes the site for show purposes only to introduce this concept to NFL, NBA, MLB, NHL, MLS, FAPL and other Sport Leagues, Corporations and Teams.",
    },
    {
      id: 1,
      text: "© 2022 NFL Enterprises LLC. NFL and the NFL shield design are registered trademarks of the National Football League. The team names, logos and uniform designs are registered trademarks of the teams indicated. All other NFL-related trademarks are trademarks of the National Football League. NFL footage © NFL Productions LLC",
    },
    {
      id: 2,
      text: '@2022 The National Basketball Association ("NBA") name and logo and the names and logos of NBA.com and the NBA teams are the property of NBA Properties, Inc. and the member teams of the NBA. All other trademarks, logos and service marks (collectively, the "Trademarks") appearing on the Site are Trademarks of their respective owners.',
    },
    {
      id: 3,
      text: "© 2001-2022 MLB Advanced Media, L.P. All rights reserved. The following are trademarks or service marks of Major League Baseball entities and may be used only with permission of Major League Baseball Properties, Inc. or the relevant Major League Baseball entity: Major League, Major League Baseball, MLB, the silhouetted batter logo, World Series, National League, American League, Division Series, League Championship Series, All-Star Game, and the names, nicknames, logos, uniform designs, color combinations, and slogans designating the Major League Baseball clubs and entities, and their respective mascots, events and exhibitions.",
    },
    {
      id: 4,
      text: "@2022, NHL, the NHL Shield, the word mark and image of the Stanley Cup, Center Ice name and logo, NHL All NHL logos and marks and NHL team logos and marks depicted herein are the property of the NHL and the respective teams and may not be reproduced without the prior written consent of NHL Enterprises, LLC © NHL 2022. All Rights Reserved.",
    },
    {
      id: 5,
      text: '@2022 MLS, the content and materials contained on or distributed within the Site and Services (including, without limitation, video, audio, photos, text, images, user interfaces, graphics, statistics, updated scores, news, contests, fantasy games, message features, merchandise, tickets, logos and all copyrights and intellectual property related to the Site and/or Services, MLS, Soccer United Marketing, LLC ("SUM"), any affiliate of MLS or SUM, any of the MLS member clubs or any of the MLS sponsors, licensees and other affiliates) (the "Content") are either owned by, or licensed to, MLS. The wordmarks, logos, trade names, packaging and designs of MLS, SUM, the current and former MLS member clubs and the Site and the Services are the exclusive property of MLS or our affiliates. All other word marks and logos (each, a "Trademark" and, collectively the "Trademarks") appearing on the Site and on the Services are Trademarks of their respective owners, regardless of whether such Trademarks are displayed with the trademark symbol. Images of people or places displayed as part of the Content on the Site or on the Services are either the property of, or used with permission by, MLS.',
    },
    {
      id: 6,
      text: "@2009/22, FAPL, The trade marks, logos and brand names shown on the Website are owned by the FAPL or its suppliers. Copyright © and Database Right 2009/22 The Football Association Premier League Ltd / The Football League Ltd / The Scottish Premier League Ltd / The Scottish Football League. All rights reserved.",
    },
  ],
  image: footerImage,
};
