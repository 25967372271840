import { ISliderLeaguesContent } from "../types/contents";
import argentine from "../assets/images/argentine.png";
import brazil from "../assets/images/brazil.png";
import bfl from "../assets/images/bfl.png";
import seriea from "../assets/images/seriea.png";
import bundesliga from "../assets/images/bundesliga.png";
import laliga from "../assets/images/laliga.png";
import ligue1 from "../assets/images/ligue1.png";
import portugal from "../assets/images/portugal.png";
import upl from "../assets/images/upl.png";

export const sliderLeaguesContent: ISliderLeaguesContent = {
  title: "Championship Leagues",
  slides: [
    {
      id: 0,
      src: bfl,
      title: "Bulgarian First League (Parva Liga)",
      text: "is a professional association football league, located at the top of the Bulgarian football league system.",
    },
    {
      id: 1,
      src: seriea,
      title: "Lega Serie A (Serie A TIM)",
      text: "is a professional league competition for football clubs located at the top of the Italian football league system and the winner is awarded the Scudetto",
    },
    {
      id: 2,
      src: laliga,
      title: "La Liga (Primera División)",
      text: "is the men's top professional football division of the Spanish football league system. Administered by the Liga Nacional de Fútbol Profesional, it is contested by 20 teams.",
    },
    {
      id: 3,
      src: bundesliga,
      title: "Bundesliga (Fußball-Bundesliga)",
      text: "is a professional association football league in Germany. At the top of the German football league system, the Bundesliga is Germany's primary football competition.",
    },
    {
      id: 4,
      src: ligue1,
      title: "Ligue 1 (Ligue 1 Uber Eats)",
      text: "is a French professional league for men's association football clubs. At the top of the French football league system, it is the country's primary football competition.",
    },
    {
      id: 5,
      src: argentine,
      title: "Primera División (Torneo Socios.com)",
      text: "is the country's premier football division and is the top division of the Argentine football league system.",
    },
    {
      id: 6,
      src: brazil,
      title: "Brazilian Championship A Series",
      text: "is a Brazilian professional league for men's football clubs. At the top of the Brazilian football league system, it is the country's primary football competition.",
    },
    {
      id: 7,
      src: upl,
      title: "Ukrainian Premier League (UPL)",
      text: "is the union of Ukrainian professional clubs which is responsible for the conduct of competitions between the best Ukrainian football clubs.",
    },
    {
      id: 8,
      src: portugal,
      title: "Primeira Liga (Liga Portugal Bwin)",
      text: "is the top level of the Portuguese football league system. Organised and supervised by the Liga Portugal, it is contested by 18 teams.",
    },
  ],
};
