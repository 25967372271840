import React from "react";
import "./contactUs.scss";
import { Helmet } from "react-helmet";
import ContactUs from "./ContactUs";

const ContactUsContainer = () => {
  return (
    <>
      <Helmet>
        <title>Echampionship | Contact Us</title>
      </Helmet>
      <ContactUs />
    </>
  );
};

export default ContactUsContainer;
