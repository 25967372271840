import { IHeaderContent } from "../types/contents";
import logo from '../assets/images/logo.png';

export const headerContent: IHeaderContent = {
  logo: {
    src: logo,
    link: '/',
  },
  links: [
    {
      id: 0,
      name: "Home",
      link: "/",
    },
    {
      id: 1,
      name: "Invest FC PIRIN",
      secondName: "Get your own",
      link: "https://invest.fcpirin.com/en",
    },
    {
      id: 2,
      name: "Contact Us",
      link: "/contact-us",
    },
  ],
};
